<template>
  <div>
    <v-toolbar dark color="secondary">
      <v-btn dark icon to="/retailers"><v-icon>arrow_back</v-icon></v-btn>
      <v-toolbar-title class="white--text">{{ formTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn
          dark
          text
          v-if="this.editing"
          @click.native="deleteItem(currentItem._id)"
          ><v-icon>delete</v-icon></v-btn
        >
        <v-btn dark text @click.native="saveItem"><v-icon>save</v-icon></v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card flat style="height: 80vh; overflow-y: scroll;">
      <v-container grid-list-xl>
        <v-layout wrap>
          <v-flex xs12>
            <v-text-field
              v-model="currentItem.name"
              label="Name"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="currentItem.email"
              label="Email"
              :onfocusout="emailToLowerCase()"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model.trim="currentItem.role"
              label="Role"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="currentItem.phone"
              label="Phone Number"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-autocomplete
              v-model="currentItem.removedApps"
              :items="apps"
              item-text="metadata.name"
              item-value="metadata.packageName"
              label="Excluded Apps"
              class="pl-md-2"
              outlined
              clearable
              chips
              deletable-chips
              multiple
            ></v-autocomplete>
          </v-flex>
          <v-flex xs12>
            <v-autocomplete
              v-model="currentItem.activityBlocklist"
              :items="activities"
              item-text="description"
              item-value="_id"
              label="Blocked Activities"
              class="pl-md-2"
              outlined
              clearable
              chips
              deletable-chips
              multiple
            ></v-autocomplete>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="currentItem.kioskProfile.appDwnUrl"
              label="Kiosk - App URL"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="currentItem.kioskProfile.bootanimDwnUrl"
              label="Kiosk - Bootanimation URL"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="currentItem.address"
              label="Address"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="currentItem.mailAddress"
              label="Mail Address"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="currentItem.mailCity"
              label="City"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="currentItem.mailState"
              label="State"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="currentItem.mailCountry"
              label="Country"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="currentItem.mailPostal"
              label="Postal Code"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="currentItem.notes"
              label="Notes"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="currentItem.website"
              label="Website"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="currentItem.licenses"
              label="Licenses"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "RetailerDetail",
  data() {
    return {
      editing: true,
      loading: true,
      headers: [
        { text: "Edit", value: "edit", sortable: false },
        { text: "Name", value: "name" },
        { text: "Address", value: "address" },
        { text: "Licenses", value: "licenses" },
        { text: "Email", value: "email" },
        { text: "Mail Adddress", value: "mailAddress" },
        { text: "Mail City", value: "mailCity" },
        { text: "Mail State", value: "mailState" },
        { text: "Mail Country", value: "mailCountry" },
        { text: "Mail Postal", value: "mailPostal" },
        { text: "Notes", value: "notes" },
        { text: "Phone", value: "phone" },
        { text: "Website", value: "website" },
        { text: "Role", value: "role" },
      ],
      items: [],
      apps: [],
      activities: [],
      currentItem: {
        timestamp: null,
        version: 1,
        name: "",
        address: "",
        licenses: "",
        email: "",
        mailAddress: "",
        mailCity: "",
        mailState: "",
        mailCountry: "",
        mailPostal: "",
        notes: "",
        phone: "",
        website: "",
        role: "",
      },
    };
  },
  computed: {
    formTitle() {
      return !this.editing ? "New Retailer" : "Edit Retailer";
    },
  },
  methods: {
    emailToLowerCase() {
        this.currentItem.email = this.currentItem.email.toLowerCase();
    },
    initData() {
      if (!this.editing) {
        this.currentItem = {
          timestamp: null,
          version: 1,
          name: "",
          address: "",
          licenses: "",
          email: "",
          mailAddress: "",
          mailCity: "",
          mailState: "",
          mailCountry: "",
          mailPostal: "",
          notes: "",
          phone: "",
          website: "",
          role: "",
        };
      } else {
        var link =
          "https://api.safetelecom.net/api/retailers/" +
          this.$route.params.retailer;
        axios.get(link).then((response) => {
          this.currentItem = response.data;
        });
      }

      this.loading = true;
      axios.get(`https://api.safetelecom.net/api/apps`).then((response) => {
        this.apps = response.data.sort((a, b) => {
          if (a.metadata.name < b.metadata.name) {
            return -1;
          }
          if (a.metadata.name > b.metadata.name) {
            return 1;
          }
          return 0;
        });
        this.loading = false;
      });
      axios.get(`https://api.safetelecom.net/api/activities`).then((response) => {
        this.activities = response.data.sort();
        this.loading = false;
      });
    },
    editItem(id) {
      this.editing = true;
      axios
        .get(`https://api.safetelecom.net/api/retailers/${id}`)
        .then((response) => {
          this.currentItem = response.data;
        });
      this.dialog = true;
    },
    saveItem() {
      if (this.$route.params.retailer === "new") {
        axios
          .post(`https://api.safetelecom.net/api/retailers`, this.currentItem)
          .then(() => {
            this.$router.push("/retailers");
          });
      } else {
        axios
          .put(
            `https://api.safetelecom.net/api/retailers/${this.currentItem._id}`,
            this.currentItem
          )
          .then(() => {
            this.$router.push("/retailers");
          });
      }
    },
    deleteItem(id) {
      confirm("Are you sure you want to delete this item?") &&
        axios
          .delete(`https://api.safetelecom.net/api/retailers/${id}`)
          .then(() => {
            this.$router.push("/retailers");
          });
    },
  },
  created() {
    if (this.$route.params.retailer === "new") {
      this.editing = false;
    }
    this.initData();
  },
};
</script>
